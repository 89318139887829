<template>
  <div class="landing-container">
    <div class="intro-video hidden">
      <div class="intro-video-close" @click="stopVideo">
        <img src="../../public/icons/close.svg" alt="Play icon" />
      </div>
      <video v-if="!isSmallScreen" class="video-player">
        <source src="../../public/videos/intro.mp4" type="video/mp4" />
      </video>
      <img
        v-else-if="showGif"
        src="../../public/videos/intro.gif"
        class="intro-gif"
      />
    </div>
    <div class="yt-video hidden">
      <YouTube
        width=" "
        height=" "
        class="yt-frame"
        src="https://www.youtube.com/watch?v=J5xrHGDyywc"
        @ready="onReady"
        ref="youtube"
      />
      <div class="intro-video-close" @click="stopYouTubeVideo">
        <img src="../../public/icons/close.svg" alt="Play icon" />
      </div>
    </div>
    <div v-if="playerReady" class="play-icon" @click="playYouTubeVideo">
      <img src="../../public/icons/play.svg" alt="Play icon" />
      PLAY
    </div>
  </div>
</template>

<script>
import YouTube from "vue3-youtube";
export default {
  name: "LandingView",
  components: {
    YouTube,
  },
  props: {
    playIntro: {
      type: Boolean,
      required: true,
    },
    isSmallScreen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      },
      media: null,
      playerReady: false,
      showGif: false,
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    playYouTubeVideo() {
      document.querySelector(".yt-video").classList.remove("hidden");
      this.$refs.youtube.playVideo();
    },
    stopYouTubeVideo() {
      this.$refs.youtube.pauseVideo();
      document.querySelector(".yt-video").classList.add("hidden");
    },
    playIntroVideo() {
      document.querySelector(".intro-video").classList.remove("hidden");
      this.media.play();
    },
    stopVideo() {
      if (!this.isSmallScreen) {
        this.media.pause();
        this.media.currentTime = 0;
      } else {
        this.showGif = false;
      }
      document.querySelector(".intro-video").classList.add("faded");
      document
        .querySelector(".intro-video")
        .addEventListener("transitionend", () => {
          document.querySelector(".intro-video").classList.add("hidden");
          this.emitter.emit("videoEnd");
        });
    },
  },
  mounted() {
    if (!this.isSmallScreen) {
      this.media = document.querySelector("video");
      this.media.addEventListener("ended", () => {
        setTimeout(() => {
          document.querySelector(".intro-video").classList.add("faded");
          document
            .querySelector(".intro-video")
            .addEventListener("transitionend", () => {
              document.querySelector(".intro-video").classList.add("hidden");
              this.emitter.emit("videoEnd");
            });
        }, 850);
      });
    }
    if (this.playIntro && !this.isSmallScreen) {
      this.playIntroVideo();
    } else if (this.playIntro) {
      document.querySelector(".intro-video").classList.remove("hidden");
      this.showGif = true;
      setTimeout(() => {
        this.showGif = false;
        setTimeout(() => {
          document.querySelector(".intro-video").classList.add("faded");
          document
            .querySelector(".intro-video")
            .addEventListener("transitionend", () => {
              document.querySelector(".intro-video").classList.add("hidden");
              this.emitter.emit("videoEnd");
            });
        }, 850);
      }, 7700);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
.landing-container {
  width: 100vw;
  position: relative;
  top: 0;
}
.intro-video,
.yt-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 2000;
  background: $main-bg;
  transition: opacity 1.5s ease-in;
  opacity: 1;
  &.faded {
    opacity: 0;
  }
  &.hidden {
    display: none;
  }
  &-close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 60px;
    z-index: 5000;
    cursor: pointer;
  }
}
.yt-frame,
.yt-frame ::v-deep(iframe) {
  width: 100vw;
  aspect-ratio: 16/9;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.video-player {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}
.play-icon {
  margin: $navbar-margin;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
@media only screen and (min-width: 900px) {
  .landing-container {
    height: $main-content-height;
  }
  .play-icon {
    width: $navbar-height;
    height: $navbar-height;
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 899px) {
  .landing-container {
    height: $main-content-height-mobile;
  }
  .play-icon {
    width: $navbar-height-mobile;
    font-size: 1.25rem;
    margin-right: 8vw;
  }
  .video-player {
    object-fit: cover;
  }
  .intro-gif {
    width: 100vw;
    aspect-ratio: 16/9;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
