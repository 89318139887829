<template>
  <div :class="['loader-container', { clickable: loaded }]" @click="showScene">
    <div class="loader-body">
      <div id="lottie" ref="lottie"></div>
      <div class="sentence" style="opacity: 0">
        <Transition mode="out-in" name="slide-fade" :css="animated">
          <p v-if="sentenceIdx >= 0" :key="sentenceIdx">
            {{ loadingSentences[sentenceIdx] }}
          </p>
          <p v-else :key="sentenceIdx">
            Klicken Sie, um die Schmiede zu betreten
          </p>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { loadAnimation } from "lottie-web";
export default {
  name: "LoadingView",
  data() {
    return {
      numberFrames: 90,
      lastFrame: 0,
      loadingSentences: [
        "Hammer holen",
        "Ofen vorheizen",
        "Amboss vorbereiten",
      ],
      sentenceIdx: -1,
      indexInterval: null,
      animated: false,
      loaded: false,
    };
  },
  methods: {
    showScene() {
      if (!this.loaded) return;
      this.emitter.emit("showScene");
      this.emitter.emit("playIntro");
    },
  },
  mounted() {
    let sentence = document.getElementsByClassName("sentence")[0];
    sentence.style.minHeight = `${sentence.getBoundingClientRect().height}px`;
    let player = document.getElementById("lottie");
    const animation = loadAnimation({
      container: player,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "animations/loading.json",
    });
    this.emitter.on("loadedPercentage", (percentage) => {
      this.lastFrame = this.numberFrames * percentage;
      animation.goToAndStop(this.lastFrame, true);
      if (this.lastFrame === this.numberFrames) {
        clearInterval(this.indexInterval);
        this.sentenceIdx = -1;
        this.loaded = true;
      }
    });
    this.sentenceIdx = 0;
    this.$nextTick(() => {
      sentence.style.opacity = 1;
      this.animated = true;
      this.indexInterval = setInterval(() => {
        this.sentenceIdx = Math.floor(
          Math.random() * this.loadingSentences.length
        );
      }, 3000);
      this.emitter.emit("loadThree");
    });
  },
  beforeUnmount() {
    clearInterval(this.indexInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
.loader-container {
  width: 100vw;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
}
.loader-body {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-content: center;
  width: min(500px, 80vw);
  //height: 100vh;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(-1rem);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(1rem);
  opacity: 0;
}
.clickable {
  cursor: pointer;
}
.sentence {
  min-height: 0;
  grid-row-start: 3;
  grid-row-end: 4;
  justify-self: center;
  align-self: center;
}
#lottie {
  max-width: 200px;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: center;
  align-self: center;
}
@media only screen and (min-width: 900px) {
  .sentence {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 899px) {
  .sentence {
    font-size: 2.5rem;
  }
}
</style>
