import { Curve, Vector3 } from "three";
export class CustomTube extends Curve {
  constructor(scale = 1) {
    super();
    this.scale = scale;
  }

  getPoint(t, optionalTarget = new Vector3()) {
    const tx = Math.cos((2 * Math.PI - (20 * Math.PI) / 180) * t);
    const tz = Math.sin((2 * Math.PI - (20 * Math.PI) / 180) * t);
    const ty = 0;
    return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale);
  }
}
