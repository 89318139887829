import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Landing from "../views/Landing.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
    props: true,
  },
  {
    path: "/messer",
    name: "messer",
    component: () =>
      import(/* webpackChunkName: "messer" */ "../views/Philosophy.vue"),
    props: true,
  },
  {
    path: "/workshops",
    name: "workshops",
    component: () =>
      import(/* webpackChunkName: "workshops" */ "../views/Workshops.vue"),
    props: true,
  },
  {
    path: "/aboutme",
    name: "aboutme",
    component: () =>
      import(/* webpackChunkName: "aboutme" */ "../views/About.vue"),
    props: true,
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
    props: true,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/ImpressumView.vue"),
    props: true,
  },
  {
    path: "/agb",
    name: "agb",
    component: () =>
      import(/* webpackChunkName: "agb" */ "../views/AGBView.vue"),
    props: true,
  },
  {
    path: "/widerufsbelehrung",
    name: "widerufsbelehrung",
    component: () =>
      import(
        /* webpackChunkName: "widerufsbelehrung" */ "../views/WiderufsbelehrungView.vue"
      ),
    props: true,
  },
  {
    path: "/datenschutzerklaerung",
    name: "datenschutzerklaerung",
    component: () =>
      import(
        /* webpackChunkName: "datenschutzerklaerung" */ "../views/DatenschutzerklaerungView.vue"
      ),
    props: true,
  },
];

const router = createRouter({
  history:
    process.env.NODE_ENV === "production"
      ? createWebHistory(process.env.BASE_URL)
      : createWebHashHistory(),
  routes,
});

export default router;
