<template>
  <nav>
    <span v-if="!isSmallScreen" class="navbar-logo" @click="goHome">
      <router-link to="/"
        ><img alt="monaco forge logo" src="@/../public/icons/logo.svg"
      /></router-link>
    </span>
    <router-link v-if="!isSmallScreen" to="/">Start</router-link>
    <span v-else class="navbar-logo" @click="goHome">
      <router-link to="/"
        ><img alt="monaco forge logo" src="@/../public/icons/logo.svg"
      /></router-link>
    </span>
    <router-link to="/messer">Messer</router-link>
    <router-link to="/workshops">Workshops</router-link>
    <router-link to="/aboutme">About Me</router-link>
    <router-link to="/contact">Kontakt</router-link>
  </nav>
</template>

<script>
export default {
  name: "TopNavbar",
  props: {
    isSmallScreen: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
nav {
  text-transform: uppercase;
  padding: $navbar-margin;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1000;
  & a {
    margin-inline: 3vw;
    font-weight: normal;
    color: $main-text-light;
    text-decoration: none;
    &.router-link-exact-active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
.navbar-logo {
  cursor: pointer;
  & a {
    margin-inline: 0;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 900px) {
  nav {
    height: $navbar-height;
    justify-content: center;
    & a {
      font-size: 1.5rem;
    }
  }
  .navbar-logo {
    width: $navbar-height;
    height: $navbar-height;
    position: absolute;
    left: $navbar-margin;
  }
}
@media only screen and (max-width: 899px) {
  nav {
    height: $navbar-height-mobile;
    justify-content: space-between;
    & a {
      font-size: 1rem;
    }
  }
  .navbar-logo {
    width: $navbar-height-mobile;
    height: $navbar-height-mobile;
    position: relative;
    margin-inline: 3vw;
    left: 0;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
