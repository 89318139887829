import { GLTFLoader } from "../../public/threejs/loaders/GLTFLoader";
import { EXRLoader } from "../../public/threejs/loaders/EXRLoader";
import { SVGLoader } from "../../public/threejs/loaders/SVGLoader";
const datGui = require("dat.gui");
const gltfLoader = new GLTFLoader();
const exrLoader = new EXRLoader();
const svgLoader = new SVGLoader();
import * as THREE from "three";
const textureLoader = new THREE.TextureLoader();
import {
  CSS2DObject,
  CSS2DRenderer,
} from "../../public/threejs/renderers/CSS2DRenderer.js";
import { CustomTube } from "./CustomTube";

export class ThreeHandler {
  constructor(emitter, canvas, parentNode, guiContainer) {
    this.emitter = emitter;
    this.canvas = canvas;
    this.guiContainer = guiContainer;
    this.parentNode = parentNode;
    this.canvasSize = {
      width: innerWidth,
      height: innerHeight,
    };
    this.isSmallScreen = innerWidth < 900;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.clock = new THREE.Clock();
    this.materials = {};
    this.mouse = new THREE.Vector2(innerWidth / 2, innerHeight / 2);
    this.pointer = new THREE.Vector2(innerWidth / 2, innerHeight / 2);
    this.tiltOptions = {
      xMin: -Math.PI / 10,
      xMax: Math.PI / 10,
      yMin: -Math.sqrt(2) / 2,
      yMax: Math.sqrt(2) / 2,
    };
    this.rotateClockwise = true;
    this.rotationInversionThreshold = 3;
    this.userRotateKnife = false;
    this.targetRotationZ = 0;
    this.targetRotationOnMouseDownX = 0;
    this.mouseX = 0;
    this.mouseXOnMouseDown = null;
    this.windowHalfX = window.innerWidth / 2;
    this.rotationTimeout = null;
    this.mouseMoveTimeout = null;
    this.raycaster = null;
    this.spriteTargetSize = new THREE.Vector3(0.15, 0.15, 0.15);
    this.spriteHovered = false;
    this.rotateKnifeToInitial = false;
    this.showTooltip = false;
    this.knifePosition = new THREE.Vector3();
    this.knifeRotation = new THREE.Vector3();
    this.showScene = false;
    this.spriteSizes = {
      hidden: new THREE.Vector3(0, 0, 0),
      shown: new THREE.Vector3(0.15, 0.15, 0.15),
      hovered: new THREE.Vector3(0.3, 0.3, 0.3),
    };
    this.sprites = [];
    this.spritePositions = [
      new THREE.Vector3(0.89, 0.1, -0.43),
      new THREE.Vector3(2.28, 0.4, -3),
    ];
    this.spriteColliders = [];
    this.hoveredSpriteIdx = -1;
    this.tooltips = [];
    this.tooltipLines = [];
    this.showTooltipIdx = -1;
    this.isInvertedView = 0;
    this.tooltipsText = [
      {
        title: "Hochleistungsstahl für eine ausgezeichnete Performance",
        body: `Um eine möglichst harte, dünne und schnitthaltige Klinge 
        zu erhalten verwende ich Stahl mit einem hohen Anteil an Kohlenstoff. 
        Dieser wird auch für die Herstellung von Rasierklingen verwendet.`,
        position: new THREE.Vector3(4, 0, 1),
        corner: ["left", "right"],
      },
      {
        title: "Griffästhetik trifft edle Werkstoffe",
        body: `Gerne wähle ich japanisches Griffdesign und kombiniere dieses 
        mit edlen Materialien. Individuelle Designwünsche werden ebenfalls 
        gerne umgesetzt. `,
        position: new THREE.Vector3(-2.5, 0, -2.2),
        corner: ["right", "left"],
      },
    ];

    this.start();
  }
  async loadEverything(toLoad) {
    let promises = [];
    let obj = {};
    let loadedSize = {};
    let totalSize = 0;
    let sizePromises = [];
    for (let key of Object.keys(toLoad)) {
      if (Object.keys(toLoad[key].data).length === 0) {
        continue;
      }
      for (let dataKey of Object.keys(toLoad[key].data)) {
        sizePromises.push(fetch(toLoad[key].data[dataKey], { method: "HEAD" }));
      }
    }
    let responses = await Promise.all(sizePromises);
    responses.forEach(
      (response) =>
        (totalSize += parseInt(response.headers.get("content-length")))
    );
    totalSize += totalSize * 0.1;

    for (let key of Object.keys(toLoad)) {
      if (Object.keys(toLoad[key].data).length === 0) {
        continue;
      }
      for (let dataKey of Object.keys(toLoad[key].data)) {
        promises.push(
          toLoad[key].loader
            .loadAsync(toLoad[key].data[dataKey], (infos) => {
              loadedSize[dataKey] = infos.loaded;
              let val = Object.values(loadedSize).reduce(
                (sum, el) => (sum += el),
                0
              );
              this.emitter.emit("loadedPercentage", val / totalSize);
            })
            .then((el) => (obj[dataKey] = el))
        );
      }
    }
    return Promise.all(promises).then(() => {
      return obj;
    });
  }
  /* eslint-disable-next-line */
  genMaterials() {
    //this.materials.woodMaterial = new THREE.MeshPhysicalMaterial({
    //  normalMap: this.loaded.woodNormal,
    //  map: this.loaded.woodTexture,
    //  roughnessMap: this.loaded.woodRoughness,
    //});
    //this.materials.metalMaterial = new THREE.MeshPhysicalMaterial({
    //  normalMap: this.loaded.metalNormal,
    //  map: this.loaded.metalTexture,
    //  roughnessMap: this.loaded.metalRoughness,
    //});
    //this.materials.damascusSteel = new THREE.MeshPhysicalMaterial({
    //  normalMap: this.loaded.damascusNormal,
    //  map: this.loaded.damascusTexture,
    //  roughnessMap: this.loaded.damascusRoughness,
    //  metalness: 1,
    //});
    //this.materials.damascusSteel1 = new THREE.MeshPhysicalMaterial({
    //  normalMap: this.loaded.damascus1Normal,
    //  map: this.loaded.damascus1Texture,
    //  roughnessMap: this.loaded.damascus1Roughness,
    //});
    //this.materials.steel = new THREE.MeshPhysicalMaterial({
    //  normalMap: this.loaded.steelNormal,
    //  map: this.loaded.steelTexture,
    //  roughnessMap: this.loaded.steelRoughness,
    //});
    this.materials.dark = new THREE.MeshPhysicalMaterial({
      color: 0x000000,
      roughness: 0.2,
      metalness: 1,
    });
    this.materials.whiteUI = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    });
    this.materials.purpleMetal = new THREE.MeshStandardMaterial({
      color: 0x555500,
      metalness: 1,
      roughness: 0.3,
    });
    this.materials.redMetal = new THREE.MeshStandardMaterial({
      color: 0x220000,
      metalness: 1,
      roughness: 0.3,
      emissive: 0xff0000,
    });
    this.materials.greyMetal = new THREE.MeshStandardMaterial({
      color: 0x111111,
      metalness: 1,
      roughness: 0.3,
    });
    this.materials.whiteMetal = new THREE.MeshStandardMaterial({
      color: 0xeeeeee,
      metalness: 1,
      roughness: 0.3,
    });
  }
  start() {
    this.emitter.on("changeRoute", (newRoute) => {
      this.currentRoute = newRoute;
      this.handleRouteChange();
    });
    this.emitter.on("showScene", () => {
      this.showScene = true;
      this.knife.visible = true;
      this.sprites.forEach((sprite) => (sprite.visible = true));
      //this.tooltip.visible = true;
      //this.tooltipLine.visible = true;
      this.handleRouteChange();
    });
    let exrToLoad = {
      //woodNormal: "textures/wood_normal.exr",
    };
    let texturesToLoad = {
      //woodTexture: "textures/wood_texture.jpg",
      //woodRoughness: "textures/woodRoughness.png",
      //metalNormal: "textures/metalNormal.png",
      //metalTexture: "textures/metalCombined.png",
      //metalRoughness: "textures/metalRoughness.png",
      //steelNormal: "textures/steelNormal.png",
      //steelTexture: "textures/steelGlossy.png",
      //steelRoughness: "textures/steelRoughness.png",
      //damascusNormal: "textures/Knife_Normal.png",
      //damascusTexture: "textures/Knife_Diffuse.png",
      //damascusRoughness: "textures/Knife_Roughness.png",
      //damascus1Normal: "textures/damascus1Normal.png",
      //damascus1Texture: "textures/damascus1Glossy.png",
      //damascus1Roughness: "textures/damascus1Roughness.png",
    };
    let svgToLoad = {
      //clickableSprite: "threejs/sprites/clickable.svg",
    };
    let modelsToLoad = {
      knifeGLTF: "models/knife.gltf",
    };
    this.loadEverything({
      exrLoader: { loader: exrLoader, data: exrToLoad },
      textureLoader: { loader: textureLoader, data: texturesToLoad },
      svgLoader: { loader: svgLoader, data: svgToLoad },
      gltfLoader: { loader: gltfLoader, data: modelsToLoad },
    }).then((loaded) => {
      this.loaded = loaded;
      this.genMaterials();
      this.setupScene().then(() => {
        if (this.guiContainer !== null) {
          this.setupGUI();
        }
        addEventListener("pointermove", (e) => this.handleMouseMove(e, this));
        this.animate();
        this.emitter.emit("loadedPercentage", 1);
      });
    });

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.labelRenderer = new CSS2DRenderer({
      alpha: true,
      antialias: true,
    });
    this.labelRenderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0";
    this.labelRenderer.domElement.style.left = "0";
    this.parentNode.appendChild(this.labelRenderer.domElement);

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.canvasSize.width / this.canvasSize.height,
      0.1,
      1000
    );
    //this.camera = new THREE.OrthographicCamera(
    //  innerWidth / -2,
    //  innerWidth / 2,
    //  innerHeight / 2,
    //  innerHeight / -2,
    //  0,
    //  1000
    //);
    this.camera.position.set(0, 7, 0);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.scene.add(this.camera);
    this.raycaster = new THREE.Raycaster();
    this.raycaster.layers.enableAll();

    addEventListener("resize", (e) => this.handleResize(e));
  }
  addLights() {
    let wattsToIntensity = 0.055;
    const frontLight = new THREE.RectAreaLight(0xffffff, 1, 9.9, 13);
    frontLight.intensity = wattsToIntensity * 200;
    frontLight.position.set(0.418241, 3.89041, 0.5);
    frontLight.lookAt(0, 0, 0.5);
    this.scene.add(frontLight);

    const backLight = new THREE.RectAreaLight(0xffe4da, 1, 9.5, 9.2);
    backLight.intensity = wattsToIntensity * 50;
    backLight.position.set(0.33, -5.13, 0.5);
    backLight.lookAt(0, 0, 0.5);
    this.scene.add(backLight);

    const leftLight = new THREE.RectAreaLight(0xffffff, 1, 9, 9.2);
    leftLight.intensity = wattsToIntensity * 15;
    leftLight.position.set(-4.1, -0.5, 0.5);
    leftLight.lookAt(0, 0, 0.5);
    this.scene.add(leftLight);

    const rightLight = new THREE.RectAreaLight(0xffffff, 1, 9, 9.2);
    rightLight.intensity = wattsToIntensity * 15;
    rightLight.position.set(4.8, -0.5, 0.5);
    rightLight.lookAt(0, 0, 0.5);
    this.scene.add(rightLight);

    const topLeftLight = new THREE.PointLight(0xfff7ed, 1, 5.74, 2.3);
    topLeftLight.intensity = wattsToIntensity * 200;
    topLeftLight.position.set(-4.5, 2.8, 0.65);
    topLeftLight.lookAt(0, 0, 0.5);
    this.scene.add(topLeftLight);

    const topRightLight = new THREE.PointLight(0xfff7ed, 1, 9.6, 2.6);
    topRightLight.intensity = wattsToIntensity * 200;
    topRightLight.position.set(4.4, 3.6, 0.4);
    topRightLight.lookAt(0, 0, 0.5);
    this.scene.add(topRightLight);
  }
  async setupScene() {
    await this.addKnife();
    this.addTestPlane();
    if (!this.isSmallScreen) {
      this.addSprites();

      this.addTooltips();

      this.addTooltipLine();
    }
    this.addLights();
    this.knife.visible = this.showScene;
    this.sprites.forEach((sprite) => (sprite.visible = this.showScene));
    this.tooltips.forEach((tooltip) => (tooltip.visible = this.showScene));
  }
  async addKnife() {
    this.knife = this.loaded.knifeGLTF.scene;
    this.knife.rotation.order = "ZYX";
    this.knife.traverse((child) => {
      if (!child.isMesh) return;
      //console.log(child.name, child.material.name);
      if (child.name === "Griff_Messer") {
        child.material.roughness = 2.5;
        child.material.metalness = 0.17;
        //child.material = this.materials.woodMaterial;
        //child.material = this.materials.greyMetal;
      } else if (child.name === "Ring") {
        child.material.roughness = 0.67;
        child.material.metalness = 0.8;
        //child.material = this.materials.metalMaterial;
        //child.material = this.materials.dark;
        //child.material = this.materials.greyMetal;
      } else if (child.name === "Schneide") {
        child.material.roughness = 0.3;
        //child.material = this.materials.damascusSteel;
        //child.material = this.materials.dark;
        //child.material = this.materials.redMetal;
      } else if (child.name === "Curve001") {
        //child.material = this.materials.damascusSteel;
        //child.material = this.materials.dark;
        //child.material = this.materials.purpleMetal;
      } else if (child.name === "Klinge") {
        //child.material = this.materials.steel;
        //child.material = this.materials.white;
        //child.material = this.materials.whiteMetal;
      }
    });
    this.knife.position.set(0, 0, 0.5);
    this.scene.add(this.knife);
    this.knifeCollider = new THREE.Box3().setFromObject(this.knife);
    this.knifeCollider.name = "knifeCollider";
  }
  addSprites() {
    const path = new CustomTube(1);
    const geometry = new THREE.TubeGeometry(path, 100, 0.03, 10, false);
    const mesh = new THREE.Mesh(geometry, this.materials.whiteUI);

    mesh.scale.set(0.15, 0.15, 0.15);

    const subsprite = mesh.clone();
    subsprite.scale.set(0.65, 0.65, 0.65);
    subsprite.name = "inner";

    mesh.add(subsprite);

    for (let i = 0; i < this.tooltipsText.length; i++) {
      const sprite = mesh.clone();
      this.sprites.push(sprite);
      sprite.position.copy(this.spritePositions[i]);
      sprite.rotation.y = (i * Math.PI) / 3;
      this.spriteColliders.push(new THREE.Box3().setFromObject(sprite));
      //this.spriteColliders[i].name = "spriteCollider2";
      this.knife.add(sprite);
    }
  }
  addTooltips() {
    for (let i = 0; i < this.tooltipsText.length; i++) {
      let div = document.createElement("div");
      let title = document.createElement("p");
      title.textContent = this.tooltipsText[i].title;
      title.classList.add("tooltip-title");
      let body = document.createElement("p");
      body.textContent = this.tooltipsText[i].body;
      body.classList.add("tooltip-body");
      div.appendChild(title);
      div.appendChild(body);
      div.classList.add("tooltip");
      this.tooltips.push(new CSS2DObject(div));
      this.tooltips[i].position.copy(this.tooltipsText[i].position);
      this.knife.add(this.tooltips[i]);
    }
  }
  addTestPlane() {
    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(100, 100),
      new THREE.MeshBasicMaterial({
        color: 0xff0000,
        side: THREE.DoubleSide,
        visible: false,
      })
    );
    plane.rotation.set(Math.PI / 2, 0, 0);
    plane.position.set(0, 0, 0);
    plane.name = "testPlane";
    this.scene.add(plane);
  }
  addTooltipLine() {
    for (let i = 0; i < this.tooltipsText.length; i++) {
      const points = [new THREE.Vector3(), new THREE.Vector3()];

      this.tooltipLines.push(
        new THREE.Line(
          new THREE.BufferGeometry().setFromPoints(points),
          new THREE.LineBasicMaterial({
            color: 0xffffff,
          })
        )
      );
      this.tooltips[i].add(this.tooltipLines[i]);
      this.tooltips[i].visible = false;
    }
  }
  findTooltipCorner(idx) {
    let bounding = this.tooltips[idx].element.getBoundingClientRect();
    let tooltipPos = new THREE.Vector2(
      bounding[this.tooltipsText[idx].corner[this.isInvertedView]],
      bounding.bottom
    );
    return this.findIntersection(tooltipPos);
  }
  findIntersection(position) {
    position.x = (position.x / window.innerWidth) * 2 - 1;
    position.y = -(position.y / window.innerHeight) * 2 + 1;
    this.raycaster.setFromCamera(position, this.camera);
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    let tooltipCorner = null;
    for (let intersect of intersects) {
      if (intersect.object.name !== "testPlane") continue;
      tooltipCorner = intersect.point;
    }
    if (tooltipCorner === null) {
      console.error("Intersect not working");
    }
    return tooltipCorner;
  }
  setupGUI() {
    const gui = new datGui.GUI();
    this.guiContainer.appendChild(gui.domElement);
    const knifeRot = gui.addFolder("knifeRotation");
    const knifePos = gui.addFolder("knifePosition");
    const spritePos = gui.addFolder("spritePosition");
    const spriteRot = gui.addFolder("spriteRotation");
    const tooltipPos = gui.addFolder("tooltipPosition");
    knifePos.add(this.knife.position, "x", -20, 20, 0.01);
    knifePos.add(this.knife.position, "y", -20, 20, 0.01);
    knifePos.add(this.knife.position, "z", -20, 20, 0.01);
    knifeRot.add(this.knife.rotation, "x", -3.14, 3.14, 0.001).listen();
    knifeRot.add(this.knife.rotation, "y", -3.14, 3.14, 0.001).listen();
    knifeRot.add(this.knife.rotation, "z", -3.14, 3.14, 0.001).listen();
    knifeRot.add(this.knife.rotation, "order").listen();
    if (!this.isSmallScreen) {
      spritePos.add(this.sprites[0].position, "x", -10, 10, 0.01);
      spritePos.add(this.sprites[0].position, "y", -10, 15, 0.01);
      spritePos.add(this.sprites[0].position, "z", -10, 10, 0.01);
      spriteRot.add(this.sprites[1].rotation, "x", -1, 1, 0.01);
      spriteRot.add(this.sprites[1].rotation, "y", -1, 1, 0.01);
      spriteRot.add(this.sprites[1].rotation, "z", -1, 1, 0.01);
      tooltipPos.add(this.tooltips[1].position, "x", -20, 20, 0.01);
      tooltipPos.add(this.tooltips[1].position, "y", -20, 20, 0.01);
      tooltipPos.add(this.tooltips[1].position, "z", -20, 20, 0.01);
    }
  }
  animate() {
    requestAnimationFrame(() => this.animate());
    /* eslint-disable-next-line */
    let deltaTime = this.clock.getDelta();

    this.knifeCollider.setFromObject(this.knife);
    this.animateKnife(deltaTime);
    if (this.currentRoute === "landing" && !this.isSmallScreen) {
      this.spriteColliders[0].setFromObject(this.sprites[0]);
      this.spriteColliders[1].setFromObject(this.sprites[1]);
      this.checkRays();
      this.animateSprite(deltaTime);
      this.animateTooltip();
    }
    this.labelRenderer.render(this.scene, this.camera);
    this.renderer.render(this.scene, this.camera);
  }
  /* eslint-disable-next-line */
  animateKnife(deltaTime) {
    let movementFactor = 2;
    switch (this.currentRoute) {
      case "landing":
        this.knife.position.x +=
          (this.knifePosition.x - this.knife.position.x) * 0.1;
        this.knife.position.y +=
          (this.knifePosition.y - this.knife.position.y) * 0.1;
        this.knife.position.z +=
          (this.knifePosition.z - this.knife.position.z) * 0.1;

        this.knife.rotation.x =
          ((innerHeight - this.mouse.y) / innerHeight) *
            (this.tiltOptions.xMax - this.tiltOptions.xMin) +
          this.tiltOptions.xMin;
        this.knife.rotation.y +=
          (this.knifeRotation.y - this.knife.rotation.y) * 0.1;
        if (this.rotateKnifeToInitial) {
          // When user hovers on tooltip
          this.knife.rotation.z +=
            (this.targetRotationZ - this.knife.rotation.z) * 0.1;
          if (Math.abs(this.targetRotationZ - this.knife.rotation.z) < 0.03) {
            this.showTooltip = true;
            if (this.rotationTimeout === null) {
              this.rotationTimeout = setTimeout(() => {
                this.rotateKnifeToInitial = false;
                this.rotationTimeout = null;
                this.targetRotationZ = this.knife.rotation.z;
                this.targetRotationOnMouseDownX = this.knife.rotation.z;
              }, 550);
            }
          }
        } else if (!this.userRotateKnife) {
          // std rotation
          let newValue =
            this.knife.rotation.z +
            (this.rotateClockwise ? 1 : -1) * 0.35 * deltaTime;
          let divisor = Math.floor(newValue / (2 * Math.PI));
          newValue -= divisor * Math.PI * 2;
          this.knife.rotation.z = newValue;
          this.mouseXOnMouseDown = null;
          this.targetRotationZ = 0;
          this.spriteHovered = false;
        } else {
          // when user moves mouse
          this.knife.rotation.z +=
            (this.targetRotationZ - this.knife.rotation.z) * 0.1;
        }
        break;
      case "contact":
      case "workshops":
      case "aboutme":
      case "messer":
        this.knife.position.x +=
          (this.knifePosition.x - this.knife.position.x) *
          movementFactor *
          deltaTime;
        this.knife.position.y +=
          (this.knifePosition.y - this.knife.position.y) *
          movementFactor *
          deltaTime;
        this.knife.position.z +=
          (this.knifePosition.z - this.knife.position.z) *
          movementFactor *
          deltaTime;

        this.knife.rotation.x +=
          (this.knifeRotation.x - this.knife.rotation.x) *
          movementFactor *
          deltaTime;
        this.knife.rotation.y +=
          (this.knifeRotation.y - this.knife.rotation.y) *
          movementFactor *
          deltaTime;
        this.knife.rotation.z +=
          (this.knifeRotation.z - this.knife.rotation.z) *
          movementFactor *
          deltaTime;
        break;
      default:
        break;
    }
  }
  animateSprite(deltaTime) {
    if (this.spriteHovered && this.hoveredSpriteIdx !== -1) {
      this.sprites[this.hoveredSpriteIdx].rotation.y += -4 * deltaTime * 1.4;
      this.sprites[this.hoveredSpriteIdx].traverse((child) => {
        if (child.name === "inner") {
          child.rotation.y += 6 * deltaTime * 1.4;
        }
      });
    }
    for (let i = 0; i < this.sprites.length; i++) {
      this.sprites[i].position.y =
        (this.knife.rotation.z > -Math.PI / 2 &&
          this.knife.rotation.z < Math.PI / 2) ||
        this.knife.rotation.z < (-3 * Math.PI) / 2 ||
        this.knife.rotation.z > (3 * Math.PI) / 2
          ? this.spritePositions[i].y
          : -this.spritePositions[i].y;
      if (i === this.hoveredSpriteIdx) {
        let newVec = this.spriteSizes.hovered.clone();
        newVec
          .sub(this.sprites[i].scale)
          .multiplyScalar(0.2)
          .add(this.sprites[i].scale);
        this.sprites[i].scale.copy(newVec);
        continue;
      }
      let newVec = this.spriteTargetSize.clone();
      newVec
        .sub(this.sprites[i].scale)
        .multiplyScalar(0.2)
        .add(this.sprites[i].scale);
      this.sprites[i].scale.copy(newVec);

      this.sprites[i].rotation.y += deltaTime * 1.4;
      this.sprites[i].traverse((child) => {
        if (child.name === "inner") {
          child.rotation.y += -4 * deltaTime * 1.4;
        }
      });
    }
  }
  animateTooltip() {
    for (let i = 0; i < this.tooltips.length; i++) {
      let geometryPosition = this.tooltipLines[i].geometry.attributes.position;
      let points = geometryPosition.array;
      const initialPoint = this.tooltips[i].worldToLocal(
        this.findTooltipCorner(i)
      );
      points[0] = initialPoint.x;
      points[1] = initialPoint.y;
      points[2] = initialPoint.z;
      const finalPoint = this.tooltips[i].worldToLocal(
        this.sprites[i].getWorldPosition(new THREE.Vector3())
      );
      points[3] = finalPoint.x;
      points[4] = finalPoint.y;
      points[5] = finalPoint.z;
      geometryPosition.needsUpdate = true;
      if (this.showTooltip && this.showTooltipIdx === i) {
        //this.tooltip.element.classList.remove("hidden");
        this.tooltips[i].visible = true;
        setTimeout(() => {
          this.tooltipLines[i].visible = true;
        }, 10);
        this.showTooltip = false;
      } else if (this.showTooltip) {
        this.tooltips[i].visible = false;
        this.tooltipLines[i].visible = false;
      } else if (!(this.spriteHovered || this.rotateKnifeToInitial)) {
        //this.tooltip.element.classList.add("hidden");
        this.tooltips[i].visible = false;
        this.tooltipLines[i].visible = false;
      }
    }
  }
  checkRays() {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    let target = new THREE.Vector3();
    let targetFound = false;
    for (let i = 0; i < this.spriteColliders.length; i++) {
      if (this.raycaster.ray.intersectBox(this.spriteColliders[i], target)) {
        //this.spriteTargetSize = this.spriteSizes.hovered;
        let divPI = Math.floor(this.knife.rotation.z / (2 * Math.PI));
        let unitPI = this.knife.rotation.z - divPI * 2 * Math.PI;
        // 0 , Math.PI
        // 0, - Math.PI
        if (!this.spriteHovered && unitPI >= (3 * Math.PI) / 2) {
          this.targetRotationZ = 2 * Math.PI - Math.PI / 13;
          this.isInvertedView = 0;
        } else if (!this.spriteHovered && unitPI > Math.PI / 2) {
          this.targetRotationZ = Math.PI + Math.PI / 13;
          this.isInvertedView = 1;
        } else if (!this.spriteHovered && unitPI >= 0) {
          this.targetRotationZ = -Math.PI / 13;
          this.isInvertedView = 0;
        } else if (!this.spriteHovered) {
          this.targetRotationZ = -Math.PI / 13;
          this.isInvertedView = 0;
        }
        this.spriteHovered = true;
        this.hoveredSpriteIdx = i;
        this.showTooltipIdx = i;
        this.rotateKnifeToInitial = true;
        targetFound = true;
      }
    }
    if (targetFound) return;
    if (this.raycaster.ray.intersectBox(this.knifeCollider, target)) {
      this.spriteTargetSize = this.spriteSizes.shown;
      this.showTooltipIdx = -1;
      this.hoveredSpriteIdx = -1;
    } else {
      this.spriteTargetSize = this.spriteSizes.hidden;
      this.showTooltipIdx = -1;
      this.hoveredSpriteIdx = -1;
    }
  }
  handleResize() {
    this.canvasSize.width = innerWidth;
    this.canvasSize.height = innerHeight;
    this.camera.aspect = this.canvasSize.width / this.canvasSize.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.labelRenderer.setSize(this.canvasSize.width, this.canvasSize.height);
    this.handleRouteChange();
  }
  handleMouseMove(e) {
    e.preventDefault();
    if (Math.abs(e.clientX - this.mouseX - this.windowHalfX) < 2) return;
    if (this.mouseXOnMouseDown === null) {
      this.mouseXOnMouseDown = e.clientX - this.windowHalfX;
      this.targetRotationOnMouseDownX = this.knife.rotation.z;
    } else {
      this.rotateClockwise = e.clientX < this.mouseX + this.windowHalfX;
    }
    this.userRotateKnife = true;
    this.mouseX = e.clientX - this.windowHalfX;

    if (!this.rotateKnifeToInitial) {
      this.targetRotationZ =
        this.targetRotationOnMouseDownX -
        (this.mouseX - this.mouseXOnMouseDown) * 0.002;
    }
    if (this.mouseMoveTimeout !== null) {
      clearTimeout(this.mouseMoveTimeout);
    }
    this.mouseMoveTimeout = setTimeout(() => {
      this.userRotateKnife = false;
    }, 300);

    //if (xDiff > this.rotationInversionThreshold && !this.rotateClockwise) {
    //  this.changeDirection = true;
    //  this.rotateClockwise = true;
    //} else if (
    //  xDiff < -this.rotationInversionThreshold &&
    //  this.rotateClockwise
    //) {
    //  this.changeDirection = true;
    //  this.rotateClockwise = false;
    //}

    this.mouse.x = e.clientX;
    this.mouse.y = e.clientY;
    this.pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
  }
  handleRouteChange() {
    if (!this.knife || !this.showScene) {
      return;
    }
    if (!this.currentRoute) {
      setTimeout(() => this.emitter.emit("whichRoute"), 1000);
    }
    if (this.currentRoute === "landing") {
      this.sprites.forEach((sprite) => (sprite.visible = true));
      this.knife.visible = true;
      this.knifePosition.x = 0;
      this.knifePosition.y = 0;
      this.knifePosition.z = 0.5;

      this.knifeRotation.y = 0;
      this.knife.rotation.order = "ZYX";
    } else if (this.currentRoute === "contact" && !this.isSmallScreen) {
      this.sprites.forEach((sprite) => (sprite.visible = false));
      let navbar = document.getElementsByTagName("nav")[0];
      if (navbar === undefined) {
        setTimeout(() => this.handleRouteChange(), 200);
        return;
      }
      let boundingBox = navbar.getBoundingClientRect();
      let navbarBottom = new THREE.Vector2(
        innerWidth / 2 - 32,
        boundingBox.bottom - innerWidth * 0.02
      );
      let point = this.findIntersection(navbarBottom);
      this.knifePosition = point;

      let vectorCamera = new THREE.Vector3(0, 0, 0).sub(this.camera.position);
      let vectorKnife = new THREE.Vector3(0, 0, point.z).sub(
        this.camera.position
      );
      this.knifeRotation.x = vectorCamera.angleTo(vectorKnife) + Math.PI / 2;
      this.knifeRotation.y = -0.76;
      this.knifeRotation.z = 0;
      this.knife.rotation.order = "XYZ";
    } else if (this.currentRoute === "workshops" && !this.isSmallScreen) {
      this.sprites.forEach((sprite) => (sprite.visible = false));
      let workshopImage = document.getElementsByClassName("workshop-img")[0];
      if (workshopImage === undefined) {
        setTimeout(() => this.handleRouteChange(), 200);
        return;
      }

      let boundingBox = workshopImage.getBoundingClientRect();
      let imageLeft = new THREE.Vector2(
        boundingBox.left - 2 * 16,
        boundingBox.top + boundingBox.height / 2 + 32
      );
      let point = this.findIntersection(imageLeft);
      this.knifePosition = point;

      let vectorCamera = new THREE.Vector3(0, 0, point.z).sub(
        this.camera.position
      );
      let vectorKnife = new THREE.Vector3()
        .copy(point)
        .sub(this.camera.position);
      this.knife.rotation.order = "ZYX";
      this.knifeRotation.x = 0;
      this.knifeRotation.y = 0.8;
      this.knifeRotation.z = -vectorCamera.angleTo(vectorKnife) - Math.PI / 2;
    } else if (this.currentRoute === "messer" && !this.isSmallScreen) {
      this.sprites.forEach((sprite) => (sprite.visible = false));
      let textParent = document.getElementsByClassName("philo-text")[0];
      if (textParent === undefined) {
        setTimeout(() => this.handleRouteChange(), 200);
        return;
      }
      let el = textParent.getElementsByTagName("div")[0];
      if (el === undefined) {
        setTimeout(() => this.handleRouteChange(), 200);
        return;
      }

      let boundingBox = el.getBoundingClientRect();
      let boundingBoxParent = textParent.getBoundingClientRect();
      let imageLeft = new THREE.Vector2(
        boundingBox.left - 2 * 16,
        boundingBoxParent.top + boundingBoxParent.height / 2 + 32
      );
      let point = this.findIntersection(imageLeft);
      this.knifePosition = point;

      let vectorCamera = new THREE.Vector3(0, 0, point.z).sub(
        this.camera.position
      );
      let vectorKnife = new THREE.Vector3()
        .copy(point)
        .sub(this.camera.position);
      this.knife.rotation.order = "ZYX";
      this.knifeRotation.x = 0;
      this.knifeRotation.y = 0.8;
      this.knifeRotation.z = -vectorCamera.angleTo(vectorKnife) - Math.PI / 2;
    } else if (this.currentRoute === "aboutme" && !this.isSmallScreen) {
      this.sprites.forEach((sprite) => (sprite.visible = false));
      let image = document.getElementsByClassName("about-images")[0];
      let text = document.getElementsByClassName("about-text")[0];
      if (image === undefined || text === undefined) {
        setTimeout(() => this.handleRouteChange(), 200);
        return;
      }

      let boundingBoxImage = image.getBoundingClientRect();
      let boundingBoxText = text.getBoundingClientRect();
      let destination = new THREE.Vector2(
        (boundingBoxImage.right + boundingBoxText.left) / 2,
        boundingBoxImage.top + boundingBoxImage.height / 2 + 32
      );
      let point = this.findIntersection(destination);
      this.knifePosition = point;

      let vectorCamera = new THREE.Vector3(0, 0, point.z).sub(
        this.camera.position
      );
      let vectorKnife = new THREE.Vector3()
        .copy(point)
        .sub(this.camera.position);
      this.knife.rotation.order = "ZYX";
      this.knifeRotation.x = 0;
      this.knifeRotation.y = 0.8;
      this.knifeRotation.z = -vectorCamera.angleTo(vectorKnife) - Math.PI / 2;
    }

    if (
      (this.isSmallScreen && this.currentRoute !== "landing") ||
      this.currentRoute === "agb" ||
      this.currentRoute === "impressum" ||
      this.currentRoute === "widerufsbelehrung" ||
      this.currentRoute === "datenschutzerklaerung"
    ) {
      this.knife.visible = false;
    } else if (this.isSmallScreen) {
      this.knife.visible = true;
    }
  }
  loadSvg(data) {
    const paths = data.paths;

    const group = new THREE.Group();

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      const fillColor = path.userData.style.fill;
      if (fillColor !== undefined && fillColor !== "none") {
        const material = new THREE.MeshBasicMaterial({
          color: new THREE.Color().setStyle(fillColor).convertSRGBToLinear(),
          opacity: path.userData.style.fillOpacity,
          transparent: true,
          side: THREE.DoubleSide,
          depthWrite: false,
        });

        const shapes = SVGLoader.createShapes(path);

        for (let j = 0; j < shapes.length; j++) {
          const shape = shapes[j];

          const geometry = new THREE.ShapeGeometry(shape);
          const mesh = new THREE.Mesh(geometry, material);

          group.add(mesh);
        }
      }

      const strokeColor = path.userData.style.stroke;

      if (strokeColor !== undefined && strokeColor !== "none") {
        const material = new THREE.MeshBasicMaterial({
          color: new THREE.Color().setStyle(strokeColor).convertSRGBToLinear(),
          opacity: path.userData.style.strokeOpacity,
          transparent: true,
          side: THREE.DoubleSide,
          depthWrite: false,
        });

        for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
          const subPath = path.subPaths[j];

          const geometry = SVGLoader.pointsToStroke(
            subPath.getPoints(),
            path.userData.style
          );

          if (geometry) {
            const mesh = new THREE.Mesh(geometry, material);

            group.add(mesh);
          }
        }
      }
    }
    return group;
  }
}
