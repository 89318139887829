<template>
  <LoaderView v-if="!loaded"></LoaderView>
  <Navbar v-if="loaded" :isSmallScreen="isSmallScreen"></Navbar>
  <router-view
    v-if="loaded"
    :isSmallScreen="isSmallScreen"
    :playIntro="playIntro"
  />
  <div class="three-container">
    <canvas id="canvas-container"></canvas>
    <div id="gui-board"></div>
  </div>
  <Footer v-if="loaded" :isSmallScreen="isSmallScreen"></Footer>
</template>

<script>
import { ThreeHandler } from "./threejs/ThreeHandler";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import LoaderView from "@/views/LoaderView";
export default {
  components: {
    Navbar,
    Footer,
    LoaderView,
  },
  data() {
    return {
      isSmallScreen: false,
      threeHandler: null,
      loaded: false,
      playIntro: false,
    };
  },
  computed: {
    // Decided to show always footer so that on ios is on the bottom hidden
    showFooter() {
      return !(
        this.$route.matched.some(({ name }) => name === "aboutme") &&
        this.isSmallScreen
      );
    },
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    goHome() {
      this.$push("/");
    },
  },
  watch: {
    loaded() {
      this.playIntro = true;
    },
    routeName() {
      this.emitter.emit("changeRoute", this.routeName);
    },
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    this.isSmallScreen = innerWidth < 900;
    let linkEl = document.createElement("link");
    linkEl.setAttribute("rel", "preload");
    if (this.isSmallScreen) {
      linkEl.setAttribute("as", "image");
      linkEl.setAttribute("href", "videos/intro.gif");
    } else {
      linkEl.setAttribute("as", "video");
      linkEl.setAttribute("href", "videos/intro.mp4");
      linkEl.setAttribute("type", "video/mp4");
    }
    document.head.appendChild(linkEl);
    addEventListener("resize", () => (this.isSmallScreen = innerWidth < 900));
    this.emitter.on("whichRoute", () => {
      this.emitter.emit("changeRoute", this.routeName);
    });
    this.emitter.on("showScene", () => (this.loaded = true));
    this.emitter.on("videoEnd", () => (this.playIntro = false));
    this.emitter.on("loadThree", () => {
      let canvasContainer = document.getElementById("canvas-container");
      let threeContainer =
        document.getElementsByClassName("three-container")[0];
      let guiContainer =
        process.env.NODE_ENV === "production"
          ? null
          : document.getElementById("gui-board");
      this.threeHandler = new ThreeHandler(
        this.emitter,
        canvasContainer,
        threeContainer,
        guiContainer
      );
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
#app {
  font-family: AlumniSans, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $main-text-light;
  min-height: calc(var(--vh, 1vh) * 100);
}
body {
  //background: linear-gradient(to bottom, #151515, #070707);
  background: #070707;
  padding: 0;
  margin: 0;
}
@supports (font-variation-settings: inherit) {
  @font-face {
    font-family: "AlumniSans";
    src: url("../public/fonts/AlumniSans/AlumniSans-VariableFont_wght.ttf");
  }
}
@supports not (font-variation-settings: inherit) {
  /* Add fallback font */
}
#canvas-container {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
}
#gui-board {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;
}
.tooltip {
  max-width: 55ch;
  text-align: left;
  position: relative;
  &-title {
    font-size: 2.5rem;
    margin: 0;
  }
  &-body {
    font-size: 1.5rem;
    margin: 0;
    margin-block-end: 0.5rem;
  }

  &::after {
    content: "";
    height: 0.8px;
    width: 100%;
    background-color: #eee;
    position: absolute;
    top: calc(100% - 0.4px);
  }
  &.hidden {
    display: none;
  }
}
.three-container {
  width: 100vw;
  position: absolute;
  top: 0;
  pointer-events: none;
  height: calc(var(--vh, 1vh) * 100);
}
#gui-board {
  pointer-events: initial;
}
</style>
