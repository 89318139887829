<template>
  <footer>
    <div class="legal">
      <router-link to="/impressum">Impressum</router-link> |
      <router-link to="/agb">AGB</router-link> |
      <router-link to="/widerufsbelehrung">WIDERUFSBELEHRUNG </router-link> |
      <router-link to="/datenschutzerklaerung"
        >Datenschutzerklärung</router-link
      >
    </div>
    <div class="names">
      By <a href="https://taserbeam.com/" target="_blank">TASERBEAM</a> &
      <a href="https://github.com/simonefranza" target="_blank"
        >SIMONE FRANZA</a
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    isSmallScreen: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
.legal {
  display: flex;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}
footer {
  z-index: 1000;
  position: relative;
  & a {
    padding-inline: 0.5rem;
    text-decoration: none;
    color: $main-text-light;
    &.router-link-exact-active {
      text-decoration: underline;
    }
  }
}
.names {
  display: block;
  & a {
    font-weight: bold;
  }
}
@media only screen and (min-width: 900px) {
  footer {
    height: $footer-height;
  }
}
@media only screen and (max-width: 899px) {
  footer {
    height: $footer-height-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
